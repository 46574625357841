import {css} from "@emotion/react";

export const main_area__container = (showAllCategories: boolean) => css({
    width: '100%',
    flex: showAllCategories ? 3.25 : 1.25,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    transition: `flex 1s`,
});
