/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { IconContainerProps as MuiIconContainerProps } from '@mui/material';
import React, { memo } from 'react';
import { rating__icon__container, rating__label__selected } from './rating.styles';
import { ratingIcons } from './rating-icons';
import { WidgetPhrases } from '../../../models/widget-model';

export type IconContainerProps = MuiIconContainerProps & {
    phrases?: WidgetPhrases;
    rating?: number;
};

export const RatingIconContainer = memo(({
                                             phrases,
                                             value,
                                             rating,
                                             ...other
                                         }: IconContainerProps) => {
    const isSelected = value === rating;

    return (
        <span css={ rating__icon__container(isSelected, ratingIcons[value].color) }{ ...other }>
            { ratingIcons[value].icon }
            { isSelected && (
                <span css={ rating__label__selected }>
                    { (phrases as any)[ratingIcons[value].label] }
                </span>
            ) }
        </span>
    );
});
