import { css, keyframes } from "@emotion/react";

export const FONT_SIZE_XL = '1.125em';

export const submit_screen__container = css({
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
});
export const submit_screen__content__container = css({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 12,
});
export const submit_screen__image_container = css({ width: '100%', margin: '3em 0' });
export const submit_screen__image = css({display: 'block', margin: 'auto', maxHeight: '20em', maxWidth: '100%', objectFit: 'contain' });
export const submit_screen__message__header = css({ textAlign: 'center' });
export const submit_screen__message__content = css({ textAlign: 'center', fontSize: FONT_SIZE_XL });

export const screen_grow = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`;

export const submit_screen__animation = css({ animation: `${ screen_grow } 0.75s` });
