import { Middleware } from '@reduxjs/toolkit'
import * as amplitude from '@amplitude/analytics-browser';

interface AmplitudeEvent {
    name: AmplitudeEventName,
    screen?: AmplitudeScreen,
    action?: AmplitudeAction,
    teamId: string,
}

enum AmplitudeEventName {
    SCREEN_VIEWED = "Screen Viewed",
    ACTION_PERFORMED = "Action Performed",
}

export enum AmplitudeScreen {
    WIDGET_LANDING = "widget-landing",
    EMENU = "emenu",
    FEEDBACK = "feedback",
}

enum AmplitudeAction {
    MENU_ORDER = "menu-order",
    FEEDBACK_SUBMITTED = "feedback-submitted",
    TIPS_CLICKED = "tips-clicked",
    SOCIAL_CLICKED = "social-clicked"
}

const actionTypeToAmplitudeEventPartial = {
    "emenu/landingIsOpen": {
        name: AmplitudeEventName.SCREEN_VIEWED,
        screen: AmplitudeScreen.WIDGET_LANDING,
    },
    "emenu/emenuIsOpen": {
        name: AmplitudeEventName.SCREEN_VIEWED,
        screen: AmplitudeScreen.EMENU,
    },
    "app/feedbackIsOpen": {
        name: AmplitudeEventName.SCREEN_VIEWED,
        screen: AmplitudeScreen.FEEDBACK,
    },
    "emenu/orderIsSubmitted": {
        name: AmplitudeEventName.ACTION_PERFORMED,
        action: AmplitudeAction.MENU_ORDER,
    },
    "app/feedbackIsSubmitted": {
        name: AmplitudeEventName.ACTION_PERFORMED,
        action: AmplitudeAction.FEEDBACK_SUBMITTED,
    },
    "app/tipsIsClickedFrom": {
        name: AmplitudeEventName.ACTION_PERFORMED,
        action: AmplitudeAction.TIPS_CLICKED,
    },
    "app/socialIsClicked": {
        name: AmplitudeEventName.ACTION_PERFORMED,
        action: AmplitudeAction.SOCIAL_CLICKED,
    }
}

if (process.env.REACT_APP_AMPLITUDE_KEY) {
    amplitude.init(process.env.REACT_APP_AMPLITUDE_KEY, { defaultTracking: false })
}

export const amplitudeMiddleware: Middleware = store => next => action => {
    const actionType = action.type as keyof typeof actionTypeToAmplitudeEventPartial
    if (actionType in actionTypeToAmplitudeEventPartial) {
        const amplitudeEvent: AmplitudeEvent = {
            ...actionTypeToAmplitudeEventPartial[actionType],
            ...{ teamId: store.getState().emenu.teamId },
            ...(actionType === "app/tipsIsClickedFrom" ? { "from-screen": action.payload } : {}),
            ...(actionType === "app/socialIsClicked" ? { "social-link": action.payload } : {})
        }
        amplitude.track(amplitudeEvent.name, omit(amplitudeEvent, "name"))
        console.log(amplitudeEvent)
    }
    return next(action)
}

const omit = (obj: any, propName: string) => {
    const copy = {...obj}
    delete copy[propName]
    return copy
}