import Terrible from '../../../static/rating/terrible.png';
import { GREEN_BASIC, RED_BASIC, YELLOW_BASIC } from '../../../styles/common-styles/color-palette';
import Poor from '../../../static/rating/poor.png';
import Average from '../../../static/rating/average.png';
import Good from '../../../static/rating/good.png';
import Excellent from '../../../static/rating/excellent.png';
import React from 'react';

export const ratingIcons: {
    [index: string]: {
        icon: any;
        label: string;
        color: string;
    };
} = {
    1: {
        icon: <img src={ Terrible } alt=""/>,
        label: 'terrible_label',
        color: RED_BASIC,
    },
    2: {
        icon: <img src={ Poor } alt=""/>,
        label: 'poor_label',
        color: RED_BASIC,
    },
    3: {
        icon: <img src={ Average } alt=""/>,
        label: 'average_label',
        color: YELLOW_BASIC,
    },
    4: {
        icon: <img src={ Good } alt=""/>,
        label: 'good_label',
        color: GREEN_BASIC,
    },
    5: {
        icon: <img src={ Excellent } alt=""/>,
        label: 'excellent_label',
        color: GREEN_BASIC,
    },
};
