/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import React, { useRef } from "react";
import { TextField } from "@mui/material";
import { multiline__text__input } from "./multiline-text-input.styles";

interface TextInputProps {
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;

    placeholder?: string;
    defaultValue?: string;
    focused?: boolean;
    type?: React.InputHTMLAttributes<unknown>['type'];
    required?: boolean;
    disabled?: boolean;
    hasError?: boolean;
    errorMessage?: string;
    autocomplete?: string;
    autocompleteName?: string;
    onBlur?: () => void;
    isFull?: boolean;
    maxLines?: number;

    endAdornment?: React.ReactNode;
}

export const MultilineTextInput =
    ({
         hasError,
         onBlur,
         onChange,
         autocomplete,
         autocompleteName,
         type,
         required,
         disabled,
         placeholder,
         defaultValue,
         focused,
         isFull,
         maxLines,
         errorMessage,
     }: TextInputProps) => {
        const refObject = useRef<HTMLTextAreaElement>();
        const setCaretPosition = () => {
            setTimeout(() => {
                refObject.current?.setSelectionRange(2, 2);
            }, 50);
        };
        return (
            <TextField
                css={ multiline__text__input(isFull) }
                variant={ "filled" }
                type={ type }
                placeholder={ placeholder }
                value={ defaultValue }
                required={ required }
                disabled={ disabled }
                name={ autocompleteName }
                inputProps={ {
                    autoCapitalize: 'none',
                } }
                multiline={ true }
                onFocus={ setCaretPosition }
                maxRows={ maxLines }
                inputRef={ refObject }
                error={ hasError }
                onChange={ onChange }
                onBlur={ onBlur }
                autoFocus={ focused }
                helperText={ hasError ? errorMessage : undefined }
                autoComplete={ autocomplete }
                size={ "small" }
            />
        );
    };
