import { css } from "@emotion/react";
import { DARK_BASIC, GREY_LIGHT } from "../../styles/common-styles/color-palette";
import { REGULAR_FONT } from "../../styles/common-styles/font-styles";

export const emenu_header__img = css({
    objectFit: 'cover',
    width: '100%',
    height: '100%'
})

export const emenu_header = css({
    height: '10em',
    marginBottom: '1em',
    position: 'relative',
})

export const emenu_header__button = css({
    color: DARK_BASIC,
    height: '3em',
    width: '6em',
    position: 'absolute',
    top: '1em',
    left: '0.5em',
    backgroundColor: GREY_LIGHT,
    border: 'none',
    borderRadius: '4px',
    fontSize: '1em',
    fontFamily: REGULAR_FONT,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    p: {
        marginLeft: '0.5em'
    }
})

export const emenu_items__text = css({
    padding: '0 0.5em 0.125em',
    boxShadow: '0 1.5em 1.5em -1.5em rgba(0, 0, 0, 0.2)'
})
