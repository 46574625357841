/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports

import { css, jsx } from "@emotion/react"
import { EmenuPhrases } from "../../models/emenu-model"
import { PrimaryButton } from "../common/primary-button"
import { SecondaryButton } from "../common/secondary-button"
import { EmenuConfirmationLogo } from "./e-menu-confirmation-logo"
import { emenu_confirmation, emenu_confirmation__text } from "./e-menu-confirmation.styles"

interface EmenuConfirmationProps {
    phrases: EmenuPhrases,
    orderId: number,
    onOpenFeedback: () => void,
    onBackToMenu: () => void,
}

export const EmenuConfirmation = ({ phrases, orderId, onOpenFeedback, onBackToMenu }: EmenuConfirmationProps) => {
    return (
        <div css={ emenu_confirmation }>
            <EmenuConfirmationLogo/>
            <div css={ emenu_confirmation__text }>
                <b>{phrases.order_will_be_ready}</b>
                <p>{ `${phrases.order_number_subject}: ${orderId}` }</p>
            </div>
            <div css={ css({ marginTop: 'auto', width: '100%' }) }>
                <div css={ css({ height: '3em' }) }>
                    <PrimaryButton
                        label={phrases.to_feedback}
                        onClick={ onOpenFeedback }
                    />
                </div>
                <div css={ css({ marginTop: '1em', height: '3em' }) }>
                    <SecondaryButton
                        label={phrases.back_to_menu}
                        onClick={ onBackToMenu }
                    />
                </div>
            </div>
        </div>
    )
}