import { css } from "@emotion/react";

export const emenu_confirmation = css({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '2em',
    paddingBottom: '1em',
    alignItems: 'center'
})

export const emenu_confirmation__text = css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '3em 0'
})