import {css} from "@emotion/react";
import {GREY_BASIC} from "../../styles/common-styles/color-palette";

export const footer__container = css({
    borderTop: `${GREY_BASIC} 1px solid`,
    flex: '1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '0.5em',
    boxSizing: 'border-box',
});
