import {FeedbackRequest} from "../models/backend-api";
import {AppDispatch} from "../store/store";
import {submitFeedback} from "./submit-feedback-service";
import {feedbackIsSubmitted, setFeedbackSubmittedFlag, setShowLoadingIndicatorFlag} from "../store/features/app-slice";

export const submitFeedbackRequestHandler = (request: FeedbackRequest, dispatch: AppDispatch) => {
    submitFeedback(request)
        .then(() => {
            console.log('Feedback is submitted');
            dispatch(setFeedbackSubmittedFlag(true));
            dispatch(setShowLoadingIndicatorFlag(false));
            dispatch(feedbackIsSubmitted());
        });
};
