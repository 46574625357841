import {css} from "@emotion/react";

export const flex_container__horizontal = css({
    display: 'flex',
    justifyContent: 'center',
});

export const container__full_height = css({
    height: '100%',
});

export const container__full_width = css({
    width: '100%',
});

export const flex_container__horizontal__full_width = [flex_container__horizontal, container__full_width];
