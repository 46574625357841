/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import React, { Fragment } from "react";
import { TextInput } from "../common/text-input";
import { IconButton } from "../common/icon-button";
import { SendIcon } from "../common/send-icon";
import { MultilineTextInput } from "../common/multiline-text-input";
import { Preloader } from "../common/preloader";
import { PrimaryButton } from "../common/primary-button";
import { footer__container } from "./footer.styles";
import { WidgetPhrases } from '../../models/widget-model';

interface SubmitAreaProps {
    phrases: WidgetPhrases;

    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onClick: () => void;
    rating: number;
    defaultValue?: string;
    isPreview: boolean;
}

const RegularSubmitArea = ({
                               phrases,
                               onChange,
                               onClick,
                               rating,
                               isPreview,
                               isCommentariesEnabled,
                           }: SubmitAreaProps & { isCommentariesEnabled: boolean; }) => {
    return (
        <Fragment>
            { isCommentariesEnabled && (
                <div css={ css({ flex: 1, height: '3em', marginRight: '0.5em' }) }>
                    <TextInput placeholder={ phrases.message_placeholder } onChange={ onChange }/>
                </div>
            ) }
            <div css={ css({ flex: 1, height: "3em" }) }>
                <PrimaryButton
                    label={ phrases.send_button }
                    onClick={ onClick }
                    disabled={ !rating || isPreview }
                />
            </div>
        </Fragment>
    );
};

const CommentArea = ({ phrases, defaultValue, onChange, onClick, rating, isPreview }: SubmitAreaProps) => {
    return (
        <div css={ css({ flex: 1, height: '7em', position: 'relative' }) }>
            <MultilineTextInput
                defaultValue={ defaultValue }
                focused={ true }
                placeholder={ phrases.message_placeholder }
                onChange={ onChange }
                isFull={ true }
                maxLines={ 5 }
            />
            <div css={ css({ position: "absolute", bottom: "0", right: "0" }) }>
                <IconButton onClick={ onClick } disabled={ !rating || isPreview }>
                    <SendIcon/>
                </IconButton>
            </div>
        </div>
    );
};

interface FooterProps {
    phrases: WidgetPhrases;

    isCommentariesEnabled: boolean;
    isPreview: boolean;
    appLoading: boolean;

    comment: string;
    rating: number;

    onSubmitFeedbackHandler: () => void;
    onCommentChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

export const Footer = ({
                           phrases,
                           isCommentariesEnabled,
                           comment,
                           isPreview,
                           rating,
                           appLoading,
                           onSubmitFeedbackHandler,
                           onCommentChange,
                       }: FooterProps) => {
    return (
        <div css={ footer__container }>
            { appLoading && (
                <div css={ css({ flex: 1, height: comment ? '7em' : '3em' }) }>
                    <Preloader/>
                </div>
            ) }
            { (!comment && !appLoading) && (
                <RegularSubmitArea
                    phrases={ phrases }
                    isCommentariesEnabled={ isCommentariesEnabled }
                    onChange={ onCommentChange }
                    onClick={ onSubmitFeedbackHandler }
                    rating={ rating }
                    isPreview={ isPreview }
                />
            ) }
            { (comment && !appLoading) && (
                <CommentArea
                    phrases={ phrases }
                    defaultValue={ comment }
                    onChange={ onCommentChange }
                    onClick={ onSubmitFeedbackHandler }
                    rating={ rating }
                    isPreview={ isPreview }
                />
            ) }
        </div>
    );
};
