/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { Button } from '@mui/material';
import { secondary_button } from './secondary-button.styles';

interface SecondaryButtonProps {
    label: string;
    onClick: (e?: any) => void;
    disabled?: boolean;
}

export const SecondaryButton = ({ label, onClick, disabled }: SecondaryButtonProps) => {
    return (
        <Button
            variant={ "contained" }
            css={ secondary_button }
            disabled={ disabled }
            onClick={ onClick }
        >
            { label }
        </Button>
    );
};