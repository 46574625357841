import { css } from "@emotion/react";

export const emenu_checkout = css({
    height: '100%',
    paddingBottom: '1em',
    display: 'flex',
    flexDirection: 'column',
})

export const emenu_checkout__header = (url: string) => css({
    height: url ? '20em' : '5em',
    position: 'relative'
})

export const emenu_checkout__item = css({
    minHeight: '4em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
})