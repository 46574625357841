import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
            debug: true,
            fallbackLng: 'en',
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            },
            detection: {
                order: ['navigator'],
            },
            resources: {
                en: {
                    translation: {
                        feedback_question_negative: 'What would you like to improve?',
                        feedback_question_positive: 'What did you notice?',
                        message_placeholder: 'Message (Optional)',
                        send_button: 'Send',
                        excellent_label: 'Excellent',
                        good_label: 'Good',
                        average_label: 'Average',
                        poor_label: 'Poor',
                        terrible_label: 'Terrible',
                        show_more_categories_button: 'Show More',
                        thank_you_message: 'Thank you!',
                        thank_you_message_subheader: 'We appreciate you share the feedback on social',
                        share_on_instagram: 'Share on Instagram',
                        share_on_google_maps: 'Share on Google Maps',
                        share_on_tripadvisor: 'Share on Tripadvisor',
                        tipping_staff: 'Tipping a staff member',
                        //emenu
                        to_feedback: 'Tips and feedback',
                        open_menu: 'Make Order',
                        menu_title: 'Menu',
                        menu_description: 'Select one of the positions',
                        back: 'Back',
                        confirmation_request_title: 'Please confirm',
                        confirmation_request_description: 'Once your order is confirmed, we will start cooking and bring it to your table',
                        confirm_order: 'Confirm order',
                        back_to_menu: 'Back to menu',
                        order_will_be_ready: 'Order will be ready soon!',
                        order_number_subject: 'Order number',
                        order_went_wrong: "Oops, something went wrong :( Please try one more time or reach out receptionist"
                    },
                },
                ru: {
                    translation: {
                        feedback_question_negative: 'Что вы хотели бы улучшить?',
                        feedback_question_positive: 'Что бы вы выделили?',
                        message_placeholder: 'Комментарий',
                        send_button: 'Отправить',
                        excellent_label: 'Отлично',
                        good_label: 'Хорошо',
                        average_label: 'Нормально',
                        poor_label: 'Плохо',
                        terrible_label: 'Ужасно',
                        show_more_categories_button: 'Показать больше',
                        thank_you_message: 'Спасибо!',
                        thank_you_message_subheader: 'Мы будем признательны за отзывы в наших социальных сетях',
                        share_on_instagram: 'Поделиться в Ин💯грам',
                        share_on_google_maps: 'Поделиться в Google Maps',
                        share_on_tripadvisor: 'Поделиться в Tripadvisor',
                        tipping_staff: 'Оставить чаевые сотруднику',
                        //emenu
                        to_feedback: 'Обратная связь и чаевые',
                        open_menu: 'Меню',
                        menu_title: 'Меню',
                        menu_description: 'Для заказа выберете одну из позиций',
                        back: 'Назад',
                        confirmation_request_title: 'Пожалуйста, подтвердите',
                        confirmation_request_description: 'После подтверждения заказа мы начнем готовить и принисем его к вам на столик',
                        confirm_order: 'Подтвердить заказ',
                        back_to_menu: 'Вернуться в меню',
                        order_will_be_ready: 'Ваш заказ скоро будет готов!',
                        order_number_subject: 'Номер заказа',
                        order_went_wrong: "Ой, что-то пошло не так :( Пожалуйста, попробуйте еще раз или сообщите об этой проблеме администратору"
                    },
                },
                de: {
                    translation: {
                        feedback_question_negative: 'Was möchten Sie verbessern?',
                        feedback_question_positive: 'Was haben Sie bemerkt?',
                        message_placeholder: 'Nachricht',
                        send_button: 'Schicken',
                        excellent_label: 'Exzellent',
                        good_label: 'Gut',
                        average_label: 'Durchschnitt',
                        poor_label: 'Schlecht',
                        terrible_label: 'Abscheulich',
                        show_more_categories_button: 'Zeig mehr',
                        thank_you_message: 'Danke schön!',
                        thank_you_message_subheader: 'Wir freuen uns, dass Sie Ihr Feedback in den sozialen Netzwerken teilen',
                    },
                },
            },
        },
    )
;

export default i18n;