/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { Box } from "@mui/material";
import React from "react";
import { Footer } from "../../footer/footer";
import { Categories } from "./categories";
import { Category as CategoryModel } from "../../../models/backend-api";
import { WidgetPhrases } from '../../../models/widget-model';

const extended_feedback__container = css({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '1em',
    flex: '1',
    boxSizing: 'border-box',
});

interface ExtendedFeedbackFormProps {
    phrases: WidgetPhrases;

    isCommentariesEnabled: boolean;
    isPreview: boolean;
    appLoading: boolean;

    comment: string;
    rating: number;
    selectedCategories: string[];
    categories: CategoryModel[];
    showAllCategories: boolean;

    onCategorySelect: (category: CategoryModel) => void;
    onShowAllCategories: () => void;
    onCommentChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    onSubmitFeedbackHandler: () => void;
}

export const ExtendedFeedbackForm = ({
                                         phrases,
                                         comment,
                                         isCommentariesEnabled,
                                         isPreview,
                                         onCommentChange,
                                         onSubmitFeedbackHandler,
                                         rating,
                                         selectedCategories,
                                         categories,
                                         showAllCategories,
                                         onCategorySelect,
                                         onShowAllCategories,
                                         appLoading,
                                     }: ExtendedFeedbackFormProps) => {
    return (
        <Box css={ extended_feedback__container }>
            <Categories
                phrases={ phrases }
                categories={ categories }
                showAllCategories={ showAllCategories }
                onCategorySelect={ onCategorySelect }
                onShowAllCategories={ onShowAllCategories }
                selectedCategories={ selectedCategories }
            />
            <Footer
                phrases={ phrases }
                isCommentariesEnabled={ isCommentariesEnabled }
                comment={ comment }
                isPreview={ isPreview }
                onCommentChange={ onCommentChange }
                onSubmitFeedbackHandler={ onSubmitFeedbackHandler }
                rating={ rating }
                appLoading={ appLoading }
            />
        </Box>
    );
};
