import {css} from "@emotion/react";
import {
    DARK_BASIC,
    GREY_BASIC,
    GREY_DARK,
    GREY_LIGHT,
    GREY_MEDIUM,
    RED_LIGHT_OPAQUE,
    WHITE
} from "../../styles/common-styles/color-palette";
import {BORDER_RADIUS_SMALL} from "../../styles/common-styles/borders";
import {FONT_SIZE_M} from "../../styles/common-styles/font-sizes";
import {REGULAR_FONT} from "../../styles/common-styles/font-styles";

export const multiline__text__input = (isFull?: boolean) => css({
    width: '100%',
    height: '100%',
    border: `${GREY_BASIC} 1px solid`,
    borderRadius: BORDER_RADIUS_SMALL,

    '& .MuiTextField-root': {
        height: '100%',
        borderRadius: BORDER_RADIUS_SMALL,
    },

    '& .MuiInputLabel-root': {
        color: GREY_DARK,
        fontSize: FONT_SIZE_M,
        '&.Mui-disabled': {
            color: GREY_MEDIUM,
        },
    },

    '& .MuiFilledInput-root textarea:first-of-type': {
        height: '100% !important',
    },

    '& .MuiFilledInput-root': {
        height: '100%',
        fontFamily: REGULAR_FONT,
        fontSize: FONT_SIZE_M,
        backgroundColor: GREY_LIGHT,
        borderRadius: BORDER_RADIUS_SMALL,
        padding: '0.5em 0.25em',
        ':hover': {
            backgroundColor: GREY_LIGHT,
        },

        '&.Mui-focused': {
            backgroundColor: GREY_LIGHT,
        },
        '&.Mui-error': {
            backgroundColor: RED_LIGHT_OPAQUE,
        },
        '&.Mui-disabled': {
            backgroundColor: WHITE,
            ':before': {
                borderBottom: 'none',
            },
        },

        ':before': {
            borderBottom: 'none',
        },
        ':after': {
            border: 'none',
        },
        ':hover:not(Mui-disabled):before': {
            border: 'none',
        },
    },

    '& .MuiFilledInput-input': {
        fontSize: FONT_SIZE_M,
        height: '100%',
        color: DARK_BASIC,
        padding: `0 0.75em`,
        paddingRight: isFull ? '2em' : '0.75em',
    },
});
