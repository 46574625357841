import { EmenuConfig } from '@magictap-me/widget-component/dist/esm/types/models/emenu-model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface EmenuState extends EmenuConfig {
    isEmenuActive: boolean
}

const initialState: EmenuState = {
    isEmenuActive: false,
    imageLink: '',
    teamId: '',
    content: [],
}
export const emenuSlice = createSlice({
    name: 'emenu',
    initialState,
    reducers: {
        setEmenuActive: (state: EmenuState, action: PayloadAction<boolean>) => {
            state.isEmenuActive = action.payload
        },
        loadEmenuConfig: (state: EmenuState, action: PayloadAction<EmenuConfig>) => {
            const {content, imageLink, teamId} = action.payload
            state.content = content
            state.imageLink = imageLink
            state.teamId = teamId
            state.isEmenuActive = true
        },
        emenuIsOpen: (state: EmenuState) => {},
        orderIsSubmitted: (state: EmenuState) => {},
        landingIsOpen: (state: EmenuState) => {}
    }
})

export const {
    setEmenuActive,
    loadEmenuConfig,
    emenuIsOpen,
    orderIsSubmitted,
    landingIsOpen
} = emenuSlice.actions;

export default emenuSlice.reducer;