export const DARK_BASIC = '#181818';
export const DARK_LIGHT = '#313132';
export const DARK_LIGHT_OPAQUE = 'rgba(49, 49, 50, 0.24)';

export const GREY_DARK = '#9697AC';
export const GREY_MEDIUM = '#D9DCE8';
export const GREY_LIGHT = '#F7F9FD';
export const GREY_BASIC = '#EBEDF5';

export const BLUE_BASIC = '#3A63F3';
export const BLUE_BASIC_OPAQUE = 'rgba(58, 99, 243, 0.08)';
export const BLUE_LIGHT = '#5A7FFF';

export const GREEN_BASIC = '#2EF0B6';
export const GREEN_DARK = '#16BA89';
export const GREEN_LIGHT = '#63FFD0';
export const GREEN_BASIC_OPAQUE = 'rgba(46, 240, 182, 0.08)';
export const GREEN_LIGHT_OPAQUE = 'rgba(99, 255, 208, 0.16)';

export const RED_BASIC = '#FF004D';
export const RED_LIGHT = '#FF729C';
export const RED_LIGHT_OPAQUE = 'rgba(255, 114, 156, 0.08)';

export const WHITE = '#FFFFFF';

export const YELLOW_BASIC = '#FFB800';
export const YELLOW_LIGHT = '#eec978';

export const APP_BACKGROUND_COLOR = GREY_LIGHT;
