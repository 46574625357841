/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { Fragment } from "react";
import {
    submit_screen__animation,
    submit_screen__container,
    submit_screen__content__container,
    submit_screen__image,
    submit_screen__image_container,
    submit_screen__message__content,
    submit_screen__message__header,
} from "./thank-you-page.styles";
import { container__full_height, container__full_width } from '../../styles/common-styles/containers';
import Watermark from '../footer/watermark';
import { SocialNetworkLink } from '../common/social-network-link';
import { Icons } from '../../models/icons';
import { DEFAULT_THANK_YOU_IMAGE } from '../../models/default-images';
import { DARK_BASIC } from '../../styles/common-styles/color-palette';
import { PrimaryButton } from '../common/primary-button';
import { WidgetPhrases } from '../../models/widget-model';

interface ThankYouPageProps {
    phrases: WidgetPhrases;
    isPromoteToSocialEnabled: boolean;
    minimalFeedbackScoreForPromoteToSocial: number;
    rating: number;

    thankYouPageLogo: string | null;

    tripadvisorLink?: string;
    googleMapsLink?: string;
    instagramLink?: string;
    onSocialLinkClick?: (link: string) => void;
    onTipsLinkClick?: () => void;
}

export const ThankYouPage = ({  
                                 phrases,
                                 tripadvisorLink,
                                 googleMapsLink,
                                 instagramLink,
                                 onSocialLinkClick,
                                 onTipsLinkClick,
                                 isPromoteToSocialEnabled,
                                 minimalFeedbackScoreForPromoteToSocial,
                                 rating,
                                 thankYouPageLogo,
                             }: ThankYouPageProps) => {
    const canBePromotedToSocial = isPromoteToSocialEnabled && (rating >= minimalFeedbackScoreForPromoteToSocial);

    return (
        <div css={ [container__full_height, container__full_width, submit_screen__animation] }>
            <div css={ submit_screen__container }>
                <div css={ submit_screen__content__container }>
                    <div css={ [submit_screen__image_container] }>
                        <img css={ submit_screen__image } src={ thankYouPageLogo || DEFAULT_THANK_YOU_IMAGE } alt=""/>
                    </div>
                    <h2 css={ submit_screen__message__header }>{ phrases.thank_you_message }</h2>
                    { canBePromotedToSocial && (
                        <Fragment>
                            { (tripadvisorLink || googleMapsLink || instagramLink) && (
                                <p css={ submit_screen__message__content }>
                                    { phrases.thank_you_message_subheader }
                                </p>
                            ) }
                            <div
                                css={ css({
                                    padding: '0.5em',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '0.5em',
                                    'a:hover': {
                                        color: DARK_BASIC,
                                    },
                                }) }
                            >
                                { tripadvisorLink &&
                                    <SocialNetworkLink
                                        label={ phrases.share_on_tripadvisor }
                                        icon={ Icons.tripadvisor }
                                        link={ tripadvisorLink }
                                        onLinkClick={ onSocialLinkClick }
                                    />
                                }
                                { googleMapsLink &&
                                    <SocialNetworkLink
                                        label={ phrases.share_on_google_maps }
                                        icon={ Icons.googleMaps }
                                        link={ googleMapsLink }
                                        onLinkClick={ onSocialLinkClick }
                                    />
                                }
                                { instagramLink &&
                                    <SocialNetworkLink
                                        label={ phrases.share_on_instagram }
                                        icon={ Icons.instagram }
                                        link={ instagramLink }
                                        onLinkClick={ onSocialLinkClick }
                                    />
                                }
                                { onTipsLinkClick &&
                                    <PrimaryButton
                                        label={ phrases.tipping_staff }
                                        onClick={ onTipsLinkClick }
                                    />
                                }
                            </div>
                        </Fragment>
                    ) }
                </div>
                <Watermark appLoading={ false }/>
            </div>
        </div>
    );
};
