import {css} from "@emotion/react";
import {FONT_SIZE_XXL} from "../../../styles/common-styles/font-sizes";
import {DARK_BASIC} from "../../../styles/common-styles/color-palette";

export const categories__chips_container = css({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
})

export const categories__container = (showAll: boolean) => css({
    flex: showAll ? '5' : '2',
    marginBottom: '1em',
    padding: '0 0.5em'
});

export const categories__question = css({
    fontSize: FONT_SIZE_XXL,
    fontWeight: '700',
    color: DARK_BASIC,
    textAlign: 'left',
    marginBottom: '0.5em',
    marginLeft: '0.25em',
});
