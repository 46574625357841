import {css} from "@emotion/react";
import {DARK_BASIC, GREEN_BASIC, GREEN_LIGHT, GREY_BASIC, GREY_LIGHT} from "../../styles/common-styles/color-palette";
import {BORDER_RADIUS_SMALL} from "../../styles/common-styles/borders";

export const primary_button = css({
    backgroundColor: GREEN_BASIC,
    textTransform: 'none',
    height: '100%',
    boxSizing: 'border-box',
    boxShadow: 'none',
    width: '100%',
    color: DARK_BASIC,
    borderRadius: BORDER_RADIUS_SMALL,

    ':hover': {
        backgroundColor: GREEN_LIGHT,
        boxShadow: 'none',
    },

    ':disabled': {
        backgroundColor: GREY_LIGHT,
        color: GREY_BASIC,
    },
});
