import styled from "@emotion/styled";
import { IconButton as IconButtonMui } from "@mui/material";
import { DARK_BASIC, GREEN_BASIC } from "../../styles/common-styles/color-palette";
import { BORDER_RADIUS_SMALL } from "../../styles/common-styles/borders";

export const IconButton: any = styled(IconButtonMui)({
    width: '2em',
    height: '2em',
    color: DARK_BASIC,
    borderRadius: BORDER_RADIUS_SMALL,
    backgroundColor: 'unset',

    ':hover': {
        color: GREEN_BASIC,
        backgroundColor: 'unset',
    },
});
