/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import {
    header__background_image__container,
    header__logo,
    header__placeholders__container,
    header__team_info__text_container,
    header__team_info_container,
    header__team_location,
    header__team_name,
    header__title__row,
    header__welcome_message,
    header__welcome_message__container,
    headerStyles,
} from "./header.styles";
import { HeaderPreloader } from "./header-preloader";
import { RecipientInfo } from "../../models/backend-api";

interface HeaderProps {
    showCategories: boolean;
    showAllCategories: boolean;
    appLoading: boolean;

    welcomeMessage: string;
    logo?: string;
    recipient: RecipientInfo;

    borderRadius?: string;
}

const Header = ({
                    appLoading,
                    showAllCategories,
                    showCategories,
                    recipient,
                    welcomeMessage,
                    logo,
                    borderRadius,
                }: HeaderProps) => {

    return (
        <div css={ [headerStyles({ expanded: !showCategories })] }>
            { appLoading && <HeaderPreloader/> }
            { !appLoading && (
                <div css={ header__background_image__container(recipient.backgroundImage, borderRadius) }>
                    <div css={ header__placeholders__container }>
                        <div css={ header__welcome_message__container }>
                            <h2 css={ header__welcome_message }>
                                { welcomeMessage }
                            </h2>
                        </div>
                        <div css={ header__team_info_container(showAllCategories) }>
                            { logo && (
                                <div css={ header__title__row(showAllCategories) }>
                                    <img css={ header__logo(showAllCategories) } src={ logo } alt=""/>
                                </div>
                            ) }
                            <div css={ header__team_info__text_container(showAllCategories) }>
                                <div css={ header__title__row(showAllCategories) }>
                                    <h3 css={ header__team_name(showAllCategories) }>
                                        { recipient.name }
                                    </h3>
                                </div>
                                <div css={ header__title__row(showAllCategories) }>
                                    <p css={ header__team_location(showAllCategories) }>
                                        { recipient.info }
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) }
        </div>
    );
};

export default Header;
