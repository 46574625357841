/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { categories__chips_container, categories__container, categories__question } from "./categories.styles";
import { Category } from "./category";
import { Category as CategoryModel } from "../../../models/backend-api";
import React from "react";
import { isShowMoreSpecialCategory, getSpecialShowMoreCategoryLabel, SHOW_MORE_CATEGORY_ORDER } from './utils';
import { WidgetPhrases } from '../../../models/widget-model';

const getFeedbackCategoriesQuestion = (isNegativeFeedback: boolean, phrases: WidgetPhrases) =>
    isNegativeFeedback ? phrases.feedback_question_negative : phrases.feedback_question_positive;

interface CategoriesProps {
    phrases: WidgetPhrases;

    categories: CategoryModel[];
    selectedCategories: string[];
    showAllCategories: boolean;

    onCategorySelect: (category: CategoryModel) => void;
    onShowAllCategories: () => void;
}

export const Categories = ({
                               phrases,
                               categories,
                               onCategorySelect,
                               selectedCategories,
                               showAllCategories,
                               onShowAllCategories,
                           }: CategoriesProps) => {
    const categoriesInner = [...categories];

    if(categoriesInner.length > 5) {
        categoriesInner.splice(5, 0, {
            order: SHOW_MORE_CATEGORY_ORDER,
            label: getSpecialShowMoreCategoryLabel(phrases),
            isActive: true,
        });
    }

    return (
        <div css={ categories__container(showAllCategories) }>
            <h2 css={ categories__question }>
                { getFeedbackCategoriesQuestion(false, phrases) }
            </h2>
            <div css={ categories__chips_container }>
                { categoriesInner
                    .slice(0, showAllCategories ? undefined : 6)
                    .filter((x) => showAllCategories ? !isShowMoreSpecialCategory(x, phrases) : true)
                    .map((category, i) => {
                            return (
                                <Category
                                    phrases={ phrases }
                                    key={ i }
                                    category={ category }
                                    isSelected={ !!selectedCategories.find((label) => label === category.label) }
                                    isPositive={ true }
                                    onCategorySelect={ onCategorySelect }
                                    onShowAllCategories={ onShowAllCategories }
                                />
                            );
                        },
                    ) }
            </div>
        </div>
    );
};
