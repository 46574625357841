/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { Fragment } from "react";
import Header from "./header/header";
import { FeedbackForm } from "./body/feedback-form";
import WidgetProps from "../models/widget-model";
import { WHITE } from "../styles/common-styles/color-palette";
import { defaultPhrases } from '../models/default-phrases';
import { ThankYouPage } from './thank-you-page/thank-you-page';

const widget_component = (borderRadius?: string) => css({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: WHITE,

    fontFamily: "'Varela', sans-serif",
    fontSize: '1rem',

    boxShadow: `0px 8px 24px rgba(14, 28, 40, 0.04)`,
    borderRadius,
});

const FeedbackWidget = (
    {
        phrases,
        categories,
        isCommentariesEnabled,
        isPreview,
        logo,
        recipient,
        welcomeMessage,
        showCategories,
        showAllCategories,
        appLoading,
        rating,
        comment,
        selectedCategories,
        onCommentChange,
        onRatingChange,
        onShowCategories,
        onCategorySelect,
        onShowAllCategories,
        onSubmitFeedbackHandler,
        borderRadius,
        isFeedbackSubmitted,
        tripadvisorLink,
        googleMapsLink,
        instagramLink,
        onSocialLinkClick,
        onTipsLinkClick,
        isPromoteToSocialEnabled,
        minimalFeedbackScoreForPromoteToSocial,
        thankYouPageLogo,
    }: WidgetProps) => {

    if(!phrases) {
        phrases = { ...defaultPhrases };
    }

    return (
        // todo: do not forget to write in readme about styles, fonts, etc...
        <div css={ widget_component(borderRadius) }>
            { !isFeedbackSubmitted && (
                <Fragment>
                    <Header
                        showCategories={ showCategories }
                        showAllCategories={ showAllCategories }
                        appLoading={ appLoading }
                        welcomeMessage={ welcomeMessage }
                        recipient={ recipient }
                        logo={ logo }
                        borderRadius={ borderRadius }
                    />
                    <FeedbackForm
                        phrases={ phrases }
                        showCategories={ showCategories }
                        showAllCategories={ showAllCategories }
                        appLoading={ appLoading }
                        isCommentariesEnabled={ isCommentariesEnabled }
                        comment={ comment }
                        isPreview={ isPreview }
                        rating={ rating }
                        categories={ categories }
                        selectedCategories={ selectedCategories }
                        onCommentChange={ onCommentChange }
                        onRatingChange={ onRatingChange }
                        onCategorySelect={ onCategorySelect }
                        onShowAllCategories={ onShowAllCategories }
                        onShowCategories={ onShowCategories }
                        onSubmitFeedbackHandler={ onSubmitFeedbackHandler }
                    />
                </Fragment>
            ) }
            { isFeedbackSubmitted &&
                <ThankYouPage
                    phrases={ phrases }
                    tripadvisorLink={ tripadvisorLink }
                    googleMapsLink={ googleMapsLink }
                    instagramLink={ instagramLink }
                    onSocialLinkClick={ onSocialLinkClick }
                    onTipsLinkClick={ onTipsLinkClick }
                    rating={ rating }
                    thankYouPageLogo={ thankYouPageLogo }
                    isPromoteToSocialEnabled={ isPromoteToSocialEnabled }
                    minimalFeedbackScoreForPromoteToSocial={ minimalFeedbackScoreForPromoteToSocial || 0 }
                />
            }
        </div>
    );
};

export default FeedbackWidget;
