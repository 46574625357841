/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports

import { css, jsx } from "@emotion/react";
import { EmenuItemProps } from "../../models/emenu-model";
import { emenu_item } from "./e-menu-item.styles";
import { GREY_DARK } from '../../styles/common-styles/color-palette';
import { FONT_SIZE_M } from '../../styles/common-styles/font-sizes';

type EmenuItemRowProps = Pick<EmenuItemProps, 'id' | 'name' | 'description'> & {
    onClick?: (id: string) => void,
    disabled?: boolean
}

export const EmenuItem = ({ id, name, description, onClick, disabled }: EmenuItemRowProps) => {
    return (
        <div css={ emenu_item } onClick={ () => {
            if(disabled !== true) onClick?.(id);
        } }>
            <b css={ css({ padding: '0 1em' }) }>{ name }</b>
            <p css={ css({ padding: '0 1em', color: GREY_DARK, fontSize: FONT_SIZE_M, marginBlock: 0 }) }>{ description }</p>
        </div>
    );
};