/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports

import { css, jsx } from "@emotion/react";
import { EmenuPhrases } from "../../models/emenu-model";
import { GREY_DARK } from "../../styles/common-styles/color-palette";
import { FONT_SIZE_M } from "../../styles/common-styles/font-sizes";
import { PrimaryButtonLoadable } from "../common/primary-button-loadable";
import { BackIcon } from "./e-menu-back-icon";
import { emenu_checkout, emenu_checkout__header, emenu_checkout__item } from "./e-menu-checkout.styles";
import { emenu_header__button, emenu_header__img } from "./e-menu-styles";

interface EmenuConfirmationProps {
    phrases: EmenuPhrases,
    id: string,
    title: string,
    description: string,
    url: string,
    isOrderProcessing: boolean,
    hasOrderError: boolean,
    onCancel: () => void,
    onConfirm: (itemId: string) => void
}

export const EmenuCheckout = ({
                                  phrases,
                                  id,
                                  title,
                                  description,
                                  url,
                                  isOrderProcessing,
                                  hasOrderError,
                                  onCancel,
                                  onConfirm,
                              }: EmenuConfirmationProps) => {
    const onConfirmHandler = () => {
        onConfirm(id);
    };
    return (
        <div css={ emenu_checkout }>
            <div css={ [emenu_checkout__header(url), { marginBottom: '2em' }] }>
                { url && <img css={ emenu_header__img } src={ url } alt="logo"/> }
                <button
                    css={ emenu_header__button }
                    onClick={ onCancel }
                >
                    <BackIcon/>
                    <p>{ phrases.back }</p>
                </button>
            </div>
            <div css={ emenu_checkout__item }>
                <b css={ css({ padding: '0 1em' }) }>{ title }</b>
                <p css={ css({ padding: '0 1em', color: GREY_DARK, fontSize: FONT_SIZE_M, marginBlock: 0 }) }>{ description }</p>
            </div>
            { hasOrderError && (
                <p>{ phrases.order_went_wrong }</p>
            ) }
            { !hasOrderError && (
                <div css={ css({ marginTop: '1em' }) }>
                    <PrimaryButtonLoadable
                        label={ phrases.confirm_order }
                        onClick={ onConfirmHandler }
                        requestLoadingIndicator={ isOrderProcessing }
                    />
                </div>
            ) }
        </div>
    );
};