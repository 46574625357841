export const BackIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" fill="none" viewBox="0 0 20 24">
            <g clipPath="url(#a)">
                <mask id="b" width="20" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
                    <path fill="#fff" d="M0 24h20V0H0v24Z" />
                </mask>
                <g mask="url(#b)">
                    <path fill="#9697AC" d="m8.828 12 4.95 4.95-1.414 1.414L6 12l6.364-6.364 1.414 1.414L8.828 12Z" />
                </g>
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h20v24H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}