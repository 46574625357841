import { css } from "@emotion/react";
import { BLUE_BASIC } from "../styles/common-styles/color-palette";

export const app__container = css({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    '@media (max-width: 555px)': {
    },
});

export const app = css({
    display: 'flex',
    flexDirection: 'column',
    width: '24em',
    height: '100%',
    '@media (max-width: 555px)': {
        width: '100%',
    },

    'a': {
        textDecoration: 'none',
        color: 'unset',

        ':hover': {
            color: BLUE_BASIC,
        }
    }
});
