/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports

import { jsx } from "@emotion/react"
import { Fragment, useState } from "react"
import { defaultEmenuPhrases, EmenuItemProps, EmenuProps } from "../models/emenu-model"
import { EmenuCheckout } from "./e-menu/e-menu-checkout"
import { EmenuConfirmation } from "./e-menu/e-menu-confirmation"
import { EmenuItems } from "./e-menu/e-menu-items"
import { EmenuStart } from "./e-menu/e-menu-start"
import Watermark from "./footer/watermark"

const findEmenuItemById: (items: EmenuItemProps[], id: string) => EmenuItemProps | undefined = (items, id) => {
    return items.find(item => item.id === id)
}

const Emenu = ({ phrases, imageLink, content, onOpenMenu, onSubmitOrder, onOpenFeedback, onTipsLinkClick }: EmenuProps) => {

    if (!phrases) phrases = defaultEmenuPhrases

    const [isMenuOpen, setMenuOpen] = useState(false)
    const [selectedItemId, setSelectedItemId] = useState<string>("")
    const [isOrderProcessing, setIsOrderProcessing] = useState(false)
    const [orderId, setOrderId] = useState<number | undefined>(undefined)
    const [hasOrderError, setHasOrderError] = useState<boolean>(false)

    const onOpenMenuHandler = () => {
        onOpenMenu()
        setMenuOpen(true)
    }
    const onCancelHandler = () => {
        setSelectedItemId("")
        onOpenMenuHandler()
    }
    const onBackToMenuHandler = () => {
        setOrderId(undefined)
        onOpenMenuHandler()
    }
    const onCloseMenuHandler = () => setMenuOpen(false)
    const onItemSelectHandler = (id: string) => {
        setSelectedItemId(id)
        onCloseMenuHandler()
    }
    const onConfirmHandler = () => {
        setIsOrderProcessing(true)
        onSubmitOrder(selectedItemId)
            .then(submittedOrderId => {
                setSelectedItemId("")
                setOrderId(submittedOrderId)
                setIsOrderProcessing(false)
            }, () => {
                setHasOrderError(true)
                setIsOrderProcessing(false)
            })
    }
    const selectedItem = findEmenuItemById(content, selectedItemId)
    return <Fragment>
        { !!orderId ? (
            <EmenuConfirmation
                phrases={ phrases }
                orderId={ orderId }
                onOpenFeedback={ onOpenFeedback }
                onBackToMenu={ onBackToMenuHandler }
            />
        ) : !!selectedItemId ? (
            <EmenuCheckout
                phrases={ phrases }
                id={ selectedItem!!.id }
                title={ selectedItem!!.name }
                description={ selectedItem!!.description }
                url={ selectedItem!!.imageLink }
                isOrderProcessing={ isOrderProcessing }
                hasOrderError={ hasOrderError }
                onCancel={ onCancelHandler }
                onConfirm={ onConfirmHandler }
            />
        ) : isMenuOpen ? (
            <EmenuItems
                phrases={ phrases }
                items={ content }
                imageLink={ imageLink }
                onItemSelect={ onItemSelectHandler }
                onCloseMenu={ onCloseMenuHandler }
            />
        ) : (
            <EmenuStart
                phrases={ phrases }
                imageLink={ imageLink }
                onTipsLinkClick={ onTipsLinkClick }
                onOpenMenu={ onOpenMenuHandler }
                onOpenFeedback={ onOpenFeedback }
            />
        ) }
        <Watermark/>
    </Fragment>
}

export default Emenu