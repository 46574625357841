/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {jsx} from '@emotion/react';
import {Button} from "@mui/material";
import {primary_button} from "./primary-button.styles";

interface PrimaryButtonProps {
    label: string;
    onClick: (e?: any) => void;
    disabled?: boolean;
}

export const PrimaryButton = ({label, onClick, disabled}: PrimaryButtonProps) => {
    return (
        <Button
            variant={"contained"}
            css={primary_button}
            disabled={disabled}
            onClick={onClick}
        >
            {label}
        </Button>
    );
};
