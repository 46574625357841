/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { BORDER_RADIUS_SMALL } from '../../styles/common-styles/borders';
import { GREY_BASIC } from '../../styles/common-styles/color-palette';

const linkRegexp = /(^https?)|(^\/\/)/;

export const SocialNetworkLink = ({ label, icon, link, onLinkClick }: { label: string; icon: string; link: string; onLinkClick?: (link: string) => void }) => {
    if(!linkRegexp.test(link)) {
        link = '//' + link;
    }

    const onClickHandler = (e?: any) => onLinkClick && onLinkClick(link)

    return (
        <a
            css={ css({
                height: '3.0em',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0 0.25em 0 1em',
                borderRadius: BORDER_RADIUS_SMALL,
                backgroundColor: GREY_BASIC,
            }) }
            href={ link }
            target="_blank"
            onClick={ onClickHandler }
            referrerPolicy="no-referrer"
            rel="noreferrer"
        >
            { label }
            <img alt="icon" src={ icon }/>
        </a>
    );
};
