/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { Grow } from "@mui/material";
import { main_area__container } from "./feedback-form.styles";
import React from "react";
import { ExtendedFeedbackForm } from "./feedback-area/extended-feedback-form";
import { Watermark } from "../footer/watermark";
import { Rating } from "./rating/rating";
import { Category as CategoryModel } from "../../models/backend-api";
import { WidgetPhrases } from '../../models/widget-model';

interface FeedbackFormProps {
    phrases: WidgetPhrases;

    isPreview: boolean;
    showCategories: boolean;
    showAllCategories: boolean;
    appLoading: boolean;
    isCommentariesEnabled: boolean;

    comment: string;
    rating: number;
    selectedCategories: string[];
    categories: CategoryModel[];

    onCategorySelect: (category: CategoryModel) => void;
    onShowAllCategories: () => void;
    onCommentChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    onRatingChange: (rating: number) => void;
    onShowCategories: (show: boolean) => void;

    onSubmitFeedbackHandler: () => void;
}

export const FeedbackForm = ({
                                 phrases,
                                 appLoading,
                                 comment,
                                 categories,
                                 isCommentariesEnabled,
                                 isPreview,
                                 onCommentChange,
                                 onRatingChange,
                                 onShowCategories,
                                 onCategorySelect,
                                 onShowAllCategories,
                                 onSubmitFeedbackHandler,
                                 rating,
                                 selectedCategories,
                                 showAllCategories,
                                 showCategories,
                             }: FeedbackFormProps) => {
    return (
        <div css={ [main_area__container(showAllCategories)] }>
            <div css={ css({
                width: '100%',
                height: '100%',
                textAlign: 'center',
                fontSize: '18px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }) }>
                <Rating
                    phrases={ phrases }
                    appLoading={ appLoading }
                    rating={ rating }
                    onRatingChange={ onRatingChange }
                    onShowCategories={ onShowCategories }
                />

                { showCategories && (
                    <div css={ css({ flex: '1', flexGrow: showAllCategories ? '3' : '1' }) }>
                        <Grow css={ css({ height: '100%' }) } in={ showCategories } timeout={ 800 } mountOnEnter
                              unmountOnExit>
                            <div>
                                <ExtendedFeedbackForm
                                    phrases={ phrases }
                                    isCommentariesEnabled={ isCommentariesEnabled }
                                    comment={ comment }
                                    isPreview={ isPreview }
                                    onCommentChange={ onCommentChange }
                                    onCategorySelect={ onCategorySelect }
                                    onShowAllCategories={ onShowAllCategories }
                                    onSubmitFeedbackHandler={ onSubmitFeedbackHandler }
                                    rating={ rating }
                                    categories={ categories }
                                    showAllCategories={ showAllCategories }
                                    selectedCategories={ selectedCategories }
                                    appLoading={ appLoading }
                                />
                            </div>
                        </Grow>
                    </div>
                ) }
            </div>

            { !showCategories && <Watermark appLoading={ appLoading }/> }
        </div>
    );
};
