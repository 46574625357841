import {apiHost} from "./api-host-resolver";
import {MagictapResponse, WidgetConfig} from "../models/backend-api";

export const fetchWidgetConfig: (qrHash: string) => Promise<MagictapResponse<WidgetConfig>> = async (qrHash: string) => {
    return await fetch(`${apiHost}/api/widgets/configs?publicId=${qrHash}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then(resp => resp.json().then(x => x))
        .catch(e => console.log(e))
};
