import {css} from "@emotion/react";
import {BORDER_RADIUS_SMALL} from "../../../styles/common-styles/borders";
import {FONT_SIZE_M} from "../../../styles/common-styles/font-sizes";
import {DARK_BASIC, GREY_BASIC, GREY_DARK, GREY_LIGHT} from "../../../styles/common-styles/color-palette";

interface Props {
    isSelected: boolean;
    isPositive: boolean;
}

export const category__chips = ({isSelected}: Props) => css({
    borderRadius: BORDER_RADIUS_SMALL,
    boxSizing: 'border-box',
    color: isSelected ? DARK_BASIC : GREY_DARK,
    fontSize: FONT_SIZE_M,
    margin: '0.25em 0.25em',
    height: '3em',
    backgroundColor: isSelected ? GREY_BASIC : 'unset',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.25em 1em',
    border: `${isSelected ? GREY_LIGHT : GREY_BASIC} 1px solid`,
    '@media (min-width: 555px)': {
        ':hover': {
            backgroundColor: GREY_BASIC,
        }
    },
});
