import { orderIsSubmitted } from "../../store/features/emenu-slice";
import { AppDispatch } from "../../store/store";
import { submitOrder } from "./submit-order";

export const submitOrderRequestHandler: (tableId: string | null, menuItemId: string, dispatch: AppDispatch) => Promise<number | undefined> = async (tableId: string | null, menuItemId: string, dispatch: AppDispatch) => {
    if (!tableId) {
        return Promise.reject()
    }
    return submitOrder(tableId, menuItemId).then(resp => {
        if(resp.content) {
            dispatch(orderIsSubmitted())
            return resp.content?.number
        } else return Promise.reject();
    })
}
