import { WidgetPhrases } from './widget-model';

export const defaultPhrases: WidgetPhrases = {
    feedback_question_negative: 'What would you like to improve?',
    feedback_question_positive: 'What did you notice?',
    message_placeholder: 'Message (Optional)',
    send_button: 'Send',
    excellent_label: 'Excellent',
    good_label: 'Good',
    average_label: 'Average',
    poor_label: 'Poor',
    terrible_label: 'Terrible',
    show_more_categories_button: 'Show More',
    thank_you_message: 'Thank you!',
    thank_you_message_subheader: 'We appreciate you share the feedback on social',
    share_on_instagram: 'Share on Instagram',
    share_on_google_maps: 'Share on Google Maps',
    share_on_tripadvisor: 'Share on Tripadvisor',
    tipping_staff: 'Tipping a stuff member',
};
