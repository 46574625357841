/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports

import { css, jsx } from "@emotion/react";
import { Fragment } from "react";
import { EmenuItemProps, EmenuPhrases } from "../../models/emenu-model";
import { BackIcon } from "./e-menu-back-icon";
import { EmenuItem } from './e-menu-item';
import { e_menu_items } from "./e-menu-items.styles";
import { emenu_header, emenu_header__button, emenu_header__img, emenu_items__text } from "./e-menu-styles";
import { GREY_LIGHT } from '../../styles/common-styles/color-palette';
import { HEADERS_FONT } from '../../styles/common-styles/font-styles';
import { FONT_SIZE_XXL } from '../../styles/common-styles/font-sizes';

type EmenuItemsProps = {
    phrases: EmenuPhrases;
    imageLink: string;
    items: EmenuItemProps[];
    onItemSelect: (id: string) => void;
    onCloseMenu: () => void;
}

export const EmenuItems = ({ phrases, imageLink, items, onItemSelect, onCloseMenu }: EmenuItemsProps) => {
    return (
        <Fragment>
            <div css={ emenu_header }>
                <img css={ emenu_header__img } src={ imageLink } alt="logo"/>
                <button
                    css={ emenu_header__button }
                    onClick={ onCloseMenu }
                >
                    <BackIcon/>
                    <p>{ phrases.back }</p>
                </button>
            </div>

            <div css={ emenu_items__text }>
                <b css={ css({ fontFamily: HEADERS_FONT, fontWeight: 900, fontSize: FONT_SIZE_XXL }) }>{ phrases.menu_title }</b>
                <p>{ phrases.menu_description }</p>
            </div>
            <ol css={ e_menu_items }>
                { items.map(item => (
                    <li css={ css({
                        ':nth-of-type(even)': {
                            backgroundColor: GREY_LIGHT,
                        },
                    }) } key={ item.id }>
                        <EmenuItem
                            id={ item.id }
                            name={ item.name }
                            description={ item.description }
                            onClick={ () => onItemSelect(item.id) }
                        />
                    </li>
                )) }
            </ol>
        </Fragment>
    );
};
