/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { Rating as RatingMui } from "@mui/material";
import React, { useCallback } from "react";
import { rating__area, rating__container } from "./rating.styles";
import { ratingIcons } from './rating-icons';
import { RatingPreloader } from './rating-preloader';
import { IconContainerProps, RatingIconContainer } from './rating-icon-container';
import { WidgetPhrases } from '../../../models/widget-model';

interface RatingProps {
    phrases: WidgetPhrases;

    appLoading: boolean;
    rating: number;

    onRatingChange: (rating: number) => void;
    onShowCategories: (show: boolean) => void;
}

export const Rating = ({ phrases, appLoading, rating, onRatingChange, onShowCategories }: RatingProps) => {
    const onRatingChangeHandler = useCallback((event: React.SyntheticEvent, newValue: number | null) => {
        onRatingChange(newValue || rating);
        onShowCategories(true);
    }, [onRatingChange, onShowCategories, rating]);

    const IconContainerComponent = (props: IconContainerProps) => {
        return (<RatingIconContainer { ...props } rating={ rating } phrases={ phrases }/>);
    };

    if(appLoading) {
        return <RatingPreloader/>;
    }

    return (
        <div css={ rating__container }>
            <RatingMui
                css={ rating__area }
                value={ rating }
                onChange={ onRatingChangeHandler }
                getLabelText={ (value: number) => {
                    return ratingIcons[value].label;
                } }
                IconContainerComponent={ IconContainerComponent }
                highlightSelectedOnly
            />
        </div>
    );
};
