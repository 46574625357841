import { css } from "@emotion/react";
import { GREEN_BASIC, GREY_DARK, RED_BASIC, YELLOW_BASIC } from "../../../styles/common-styles/color-palette";
import { BORDER_RADIUS_SMALL } from "../../../styles/common-styles/borders";

export const rating__container = css({
    display: 'flex',
    justifyContent: 'center',
    minHeight: '5.5em',
    flex: '1',
    flexShrink: '0.5',
    width: '100%',
    boxShadow: `0px 8px 24px rgba(14, 28, 40, 0.04)`,
});

export const rating__label__selected = css({
    fontSize: '0.25em',
    width: '4em',
    height: '1.5em',
    color: GREY_DARK,
    position: 'relative',
    left: '-25%',
    bottom: '-0.75em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

export const rating__icon__container = (isSelected: boolean, color: string) => css({
    width: '0.65em',
    height: '0.65em',
    padding: '0.15em 0.15em',
    margin: '0 0.15em',
    borderRadius: BORDER_RADIUS_SMALL,
    backgroundColor: isSelected ? color : 'unset',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
});

export const rating__area = css({
    fontSize: '3em',
    alignItems: 'center',

    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    gridTemplateRows: '1fr',

    'label': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        'span': {
            margin: 'unset',
        }
    },

    '& label:nth-of-type(1) .MuiRating-iconFilled': {
        backgroundColor: RED_BASIC,
        marginBottom: '0.5em',
    },
    '& label:nth-of-type(2) .MuiRating-iconFilled': {
        backgroundColor: RED_BASIC,
        marginBottom: '0.5em',
    },
    '& label:nth-of-type(3) .MuiRating-iconFilled': {
        backgroundColor: YELLOW_BASIC,
        marginBottom: '0.5em',
    },
    '& label:nth-of-type(4) .MuiRating-iconFilled': {
        backgroundColor: GREEN_BASIC,
        marginBottom: '0.5em',
    },
    '& label:nth-of-type(5) .MuiRating-iconFilled': {
        backgroundColor: GREEN_BASIC,
        marginBottom: '0.5em',
    },
});
