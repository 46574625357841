import { css } from '@emotion/react';
import { skeleton__preloader } from '../../../styles/common-styles/skeleton';
import { BORDER_RADIUS_SMALL } from '../../../styles/common-styles/borders';
import React from 'react';

export const RatingPreloader = () => {
    return (
        <div css={ css({ width: '100%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }) }>
            { [1, 2, 3, 4, 5].map((_, i) =>
                <div
                    css={ [skeleton__preloader, css({
                        width: '3em',
                        height: '3em',
                        borderRadius: BORDER_RADIUS_SMALL,
                    })] }
                    key={ i }
                />,
            ) }
        </div>
    );
};
