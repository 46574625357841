import { configureStore } from "@reduxjs/toolkit";
import { appSlice } from "./features/app-slice";
import { emenuSlice } from "./features/emenu-slice";
import { amplitudeMiddleware } from "./amplitude-middleware";

export const store = configureStore({
    reducer: {
        app: appSlice.reducer,
        emenu: emenuSlice.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(amplitudeMiddleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
