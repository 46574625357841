import { css } from "@emotion/react";

export const emenu_item = css({
    height: '4em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    p: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    }
})