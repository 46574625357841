/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {jsx} from '@emotion/react';
import {skeleton__preloader} from "../../styles/common-styles/skeleton";
import {css} from "@emotion/react";
import {WHITE} from "../../styles/common-styles/color-palette";
import {BORDER_RADIUS_SMALL} from "../../styles/common-styles/borders";
import React from "react";

export const HeaderPreloader = () => {
    return (
        <div css={[skeleton__preloader, css({
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'end',
            alignItems: 'center'
        })]}
        >
            <div css={css({
                width: '14em',
                height: '2.5em',
                backgroundColor: WHITE,
                borderRadius: BORDER_RADIUS_SMALL,
                marginBottom: '1em'
            })}>
            </div>
            <div css={css({
                width: '3em',
                height: '3em',
                backgroundColor: WHITE,
                borderRadius: BORDER_RADIUS_SMALL,
                marginBottom: '0.5em'
            })}>
            </div>
            <div css={css({
                width: '7em',
                height: '1.5em',
                backgroundColor: WHITE,
                borderRadius: BORDER_RADIUS_SMALL,
                marginBottom: '0.25em'
            })}>
            </div>
            <div css={css({
                width: '14em',
                height: '1em',
                backgroundColor: WHITE,
                borderRadius: BORDER_RADIUS_SMALL,
                marginBottom: '2em'
            })}>
            </div>
        </div>
    );
};
