import { MagictapResponse, EmenuOrderResponse } from "../../models/backend-api";
import { apiHost } from "../api-host-resolver";


export const submitOrder : (tableId: string, menuItemId: string) => Promise<MagictapResponse<EmenuOrderResponse>> = async (tableId: string, menuItemId: string) => 
    await fetch(`${apiHost}/api/tables/${tableId}/order`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ menuItemId })
    })
        .then(resp => resp.json().then(x => x))
        .catch(e => console.log(e))