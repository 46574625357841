import { css } from "@emotion/react";
import { BORDER_RADIUS_SMALL } from "../../styles/common-styles/borders";
import { DARK_BASIC, DARK_LIGHT, GREY_BASIC, GREY_LIGHT } from "../../styles/common-styles/color-palette";

export const secondary_button = css({
    backgroundColor: GREY_BASIC,
    textTransform: 'none',
    height: '100%',
    boxSizing: 'border-box',
    boxShadow: 'none',
    width: '100%',
    color: DARK_BASIC,
    borderRadius: BORDER_RADIUS_SMALL,

    ':hover': {
        backgroundColor: GREY_LIGHT,
        boxShadow: 'none',
    },

    ':disabled': {
        backgroundColor: GREY_LIGHT,
        color: DARK_LIGHT,
    },
});
