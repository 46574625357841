import {createSlice, Draft, PayloadAction} from "@reduxjs/toolkit";
import {CategoryModel} from "../../models/categories";
import {FeedbackConfig, FeedbackRecipientType} from "../../models/backend-api";

interface AppState {
    qrHash: string;
    teamId: string;
    rating: number;
    comment: string | null;
    selectedCategories: string[];
    showCategories: boolean;
    feedbackSubmitted: boolean;
    showLoadingIndicator: boolean;
    showAllCategories: boolean;

    appLoading: boolean;
    feedbackConfig: FeedbackConfig;
}

const initialState: AppState = {
    teamId: '',
    qrHash: '',
    comment: null,
    rating: 0,
    selectedCategories: [],
    showCategories: false,
    feedbackSubmitted: false,
    showLoadingIndicator: false,
    showAllCategories: false,

    appLoading: false,
    feedbackConfig: {
        welcomeMessage: '',
        isCommentariesEnabled: true,
        recipient: {
            type: FeedbackRecipientType.TEAM,
            name: '',
        },
        categories: [],
        isPromoteToSocialEnabled: false,
        thankYouPageLogo: null,
    },
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setQrHash: (state: AppState, action: PayloadAction<string>) => {
            state.qrHash = action.payload;
        },
        setTeamId: (state: AppState, action: PayloadAction<string>) => {
            state.teamId = action.payload;
        },
        setRating: (state: AppState, action: PayloadAction<number>) => {
            state.rating = action.payload
        },
        changeComment: (state: AppState, action: PayloadAction<string>) => {
            state.comment = action.payload || null;
        },
        setShowCategoriesFlag: (state: AppState, action: PayloadAction<boolean>) => {
            state.showCategories = action.payload;
        },
        setShowLoadingIndicatorFlag: (state: AppState, action: PayloadAction<boolean>) => {
            state.showLoadingIndicator = action.payload;
        },
        setFeedbackSubmittedFlag: (state: AppState, action: PayloadAction<boolean>) => {
            state.feedbackSubmitted = action.payload;
        },
        selectCategory: (state: AppState, action: PayloadAction<CategoryModel>) => {
            let newCategories = [...state.selectedCategories];
            if (state.selectedCategories.find((label) => label === action.payload.label)) {
                const number = state.selectedCategories.findIndex((id) => id === action.payload.label);
                newCategories.splice(number, 1);
            } else {
                newCategories.push(action.payload.label);
            }

            state.selectedCategories = [...newCategories];
        },
        indicateAppLoading: (state: Draft<AppState>) => {
            state.appLoading = true;
        },
        loadFeedbackConfig: (state: Draft<AppState>, action: PayloadAction<FeedbackConfig>) => {
            state.feedbackConfig = {
                ...action.payload,
                recipient: {...action.payload.recipient},
                categories: [...action.payload.categories].sort(x => x.order),
            };
            state.appLoading = false;
        },
        showAllCategories: (state: Draft<AppState>) => {
            state.showAllCategories = true;
        },
        socialIsClicked: (state: Draft<AppState>, action: PayloadAction<string>) => {},
        tipsIsClickedFrom: (state: Draft<AppState>, action: PayloadAction<string>) => {},
        feedbackIsOpen: (state: Draft<AppState>) => {},
        feedbackIsSubmitted: (state: Draft<AppState>) => {}
    }
});

export const {
    setQrHash,
    setTeamId,
    setRating,
    changeComment,
    setShowCategoriesFlag,
    setShowLoadingIndicatorFlag,
    setFeedbackSubmittedFlag,
    selectCategory,
    indicateAppLoading,
    loadFeedbackConfig,
    showAllCategories,
    socialIsClicked,
    tipsIsClickedFrom,
    feedbackIsOpen,
    feedbackIsSubmitted
} = appSlice.actions;

export default appSlice.reducer;
