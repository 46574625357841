/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { Emenu, FeedbackWidget } from '@magictap-me/widget-component';
import { EmenuPhrases } from '@magictap-me/widget-component/dist/esm/types/models/emenu-model';
import { WidgetPhrases } from '@magictap-me/widget-component/dist/esm/types/models/widget-model';
import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FeedbackRequest } from '../models/backend-api';
import { submitOrderRequestHandler } from '../services/e-menu/submit-order-handler';
import { fetchWidgetConfigRequestHandler } from "../services/fetch-widget-config-request-handler";
import { submitFeedbackRequestHandler } from '../services/submit-feedback-request-handler';
import { AmplitudeScreen } from '../store/amplitude-middleware';
import {
    changeComment,
    feedbackIsOpen,
    indicateAppLoading,
    selectCategory,
    setQrHash,
    setRating,
    setShowCategoriesFlag,
    setShowLoadingIndicatorFlag,
    showAllCategories as showAll,
    socialIsClicked,
    tipsIsClickedFrom,
} from "../store/features/app-slice";
import { emenuIsOpen, setEmenuActive } from '../store/features/emenu-slice';
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { app, app__container } from "./App.styles";
import { PageNotFound } from "./not-found-page/page-not-found";

const App = () => {
    const {
        qrHash,
        feedbackSubmitted,
        appLoading,
        comment,
        rating,
        showCategories,
        showAllCategories,
        selectedCategories,
        feedbackConfig: {
            welcomeMessage,
            logo,
            categories,
            recipient,
            isCommentariesEnabled,
            thankYouPageLogo,
            isPromoteToSocialEnabled,
            minimalFeedbackScoreForPromoteToSocial,
            tipsLink,
        },
    } = useAppSelector(state => state.app);
    const {
        isEmenuActive,
        imageLink,
        content
    } = useAppSelector(state => state.emenu)
    const dispatch = useAppDispatch();

    const urlSearchParams = new URLSearchParams(window.location.search);
    const qrHashParam = urlSearchParams.get('hash');

    const { t } = useTranslation();
    const phrases: WidgetPhrases = {
        average_label: t('average_label'),
        excellent_label: t('excellent_label'),
        feedback_question_negative: t('feedback_question_negative'),
        feedback_question_positive: t('feedback_question_positive'),
        good_label: t('good_label'),
        message_placeholder: t('message_placeholder'),
        poor_label: t('poor_label'),
        send_button: t('send_button'),
        show_more_categories_button: t('show_more_categories_button'),
        terrible_label: t('terrible_label'),
        thank_you_message: t('thank_you_message'),
        thank_you_message_subheader: t('thank_you_message_subheader'),
        share_on_instagram:t('share_on_instagram'),
        share_on_google_maps:t('share_on_google_maps'),
        share_on_tripadvisor:t('share_on_tripadvisor'),
        tipping_staff:t('tipping_staff'),
    };

    const emenuPhrases : EmenuPhrases = {
        to_feedback: t('to_feedback'),
        open_menu: t('open_menu'),
        menu_title: t('menu_title'),
        menu_description: t('menu_description'),
        back: t('back'),
        confirmation_request_title: t('confirmation_request_title'),
        confirmation_request_description: t('confirmation_request_description'),
        confirm_order: t('confirm_order'),
        back_to_menu: t('back_to_menu'),
        order_will_be_ready: t('order_will_be_ready'),
        order_number_subject: t('order_number_subject'),
        order_went_wrong: t('order_went_wrong'),
        tipping_staff: t('tipping_staff'),
    }

    useEffect(() => {
        dispatch(setQrHash(qrHashParam || ''));
    },
        []);

    useEffect(() => {
        dispatch(indicateAppLoading());
        fetchWidgetConfigRequestHandler(qrHash, dispatch);
    }, [qrHash]);

    const showAllCategoriesHandler = () => {
        dispatch(showAll());
    };

    const onShowCategoriesHandler = () => {
        dispatch(setShowCategoriesFlag(true));
    };

    const selectCategoryHandler = (category: any) => {
        dispatch(selectCategory(category));
    };

    const changeCommentHandler = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        dispatch(changeComment(e.target.value));
    };

    const changeRatingHandler = (rating: number) => {
        dispatch(setRating(rating));
    };

    const onSubmitFeedbackHandler = () => {
        dispatch(setShowLoadingIndicatorFlag(true));
        const feedbackRequest: FeedbackRequest = {
            qrHash,
            content: {
                rating,
                selectedCategories,
                comment,
            },
        };
        submitFeedbackRequestHandler(feedbackRequest, dispatch);
    };

    const onOpenFeedbackHandler = () => {
        dispatch(setEmenuActive(false))
        dispatch(feedbackIsOpen())
    }

    const onSocialLinkClickHandler = (socialLink: string) => {
        dispatch(socialIsClicked(socialLink))
    }

    const onTipsLinkClickFromFeedbackHandler = tipsLink ? () => {
        dispatch(tipsIsClickedFrom(AmplitudeScreen.FEEDBACK))
        window.open(tipsLink, '_blank')
    } : undefined

    const onTipsLinkClickFromEmenuHandler = tipsLink ? () => {
        dispatch(tipsIsClickedFrom(AmplitudeScreen.EMENU))
        window.open(tipsLink, '_blank') 
    } : undefined

    const onOpenMenuHandler = () => {
        dispatch(emenuIsOpen())
    }

    return (
        <div css={ app__container }>
            <div css={ app }>
                { (!qrHashParam) && <PageNotFound/> }

                { qrHash &&
                    <Fragment>
                        { isEmenuActive &&
                            <Emenu
                                phrases={ emenuPhrases }
                                imageLink={ imageLink }
                                onTipsLinkClick={ onTipsLinkClickFromEmenuHandler }
                                content={ content }
                                onOpenMenu={ onOpenMenuHandler }
                                onSubmitOrder={ orderedItemId => submitOrderRequestHandler(qrHashParam, orderedItemId, dispatch) }
                                onOpenFeedback={ onOpenFeedbackHandler }
                            />
                        }
                        { !isEmenuActive  &&
                            <FeedbackWidget
                                phrases={ phrases }
                                isPreview={ false }
                                welcomeMessage={ welcomeMessage }
                                isCommentariesEnabled={ isCommentariesEnabled }
                                categories={ categories }
                                showCategories={ showCategories }
                                appLoading={ appLoading }
                                recipient={ { ...recipient } }
                                logo={ logo || '' }
                                onShowAllCategories={ showAllCategoriesHandler }
                                showAllCategories={ showAllCategories }
                                comment={ comment || '' }
                                rating={ rating }
                                selectedCategories={ selectedCategories }
                                onSubmitFeedbackHandler={ onSubmitFeedbackHandler }
                                onCommentChange={ changeCommentHandler }
                                onCategorySelect={ selectCategoryHandler }
                                onRatingChange={ changeRatingHandler }
                                onShowCategories={ onShowCategoriesHandler }
                                isFeedbackSubmitted={ feedbackSubmitted }
                                isPromoteToSocialEnabled={ isPromoteToSocialEnabled }
                                thankYouPageLogo={ thankYouPageLogo }
                                minimalFeedbackScoreForPromoteToSocial={ minimalFeedbackScoreForPromoteToSocial }
                                tripadvisorLink={ recipient.tripadvisorLink }
                                googleMapsLink={ recipient.googleMapsLink }
                                instagramLink={ recipient.instagramLink }
                                onSocialLinkClick = {onSocialLinkClickHandler}
                                onTipsLinkClick={ onTipsLinkClickFromFeedbackHandler }
                            /> }
                    </Fragment>
                }
            </div>
        </div>
    );
};

export default App;
