import { css } from "@emotion/react"

export const emenu_start__button = css({
    marginBottom: '0.5em',
    height: '3em'
})

export const emenu_start__buttons__wrapper = css({
    width: '100%',
    height: '100%',
    marginTop: '2em',
    padding: '0 0.5em',
    boxSizing: 'border-box'
})