import {fetchWidgetConfig} from "./fetch-widget-config";
import {MagictapResponse, WidgetConfig} from "../models/backend-api";
import {AppDispatch} from "../store/store";
import {feedbackIsOpen, loadFeedbackConfig} from "../store/features/app-slice";
import { landingIsOpen, loadEmenuConfig } from "../store/features/emenu-slice";

export const fetchWidgetConfigRequestHandler = (qrHash: string, dispatch: AppDispatch) => {
    if (!qrHash) {
        return
    }

    fetchWidgetConfig(qrHash)
        .then((response: MagictapResponse<WidgetConfig>) => {
            if (response.errorCode || !response.content) {
                return;
            }
            if(!!response.content.menu) {
                dispatch(loadEmenuConfig(response.content.menu))
                dispatch(landingIsOpen())
            } else {
                dispatch(feedbackIsOpen())
            }
            dispatch(loadFeedbackConfig(response.content.feedbackConfig));
        })
        .catch((reason) => console.error(JSON.stringify(reason)));
};
