import {FeedbackRequest} from "../models/backend-api";
import {apiHost} from "./api-host-resolver";

export const submitFeedback = async (feedbackRequest: FeedbackRequest) =>
    await fetch(`${apiHost}/api/feedbacks/${feedbackRequest.qrHash}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(feedbackRequest.content)
    })
        .then(resp => resp.json().then(x => x))
        .catch(e => console.log(e))
