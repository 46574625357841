import { css } from "@emotion/react";
import { FONT_SIZE_M, FONT_SIZE_XXL, FONT_SIZE_XXXL } from "../../styles/common-styles/font-sizes";
import { BLUE_BASIC, WHITE } from "../../styles/common-styles/color-palette";
import { BORDER_RADIUS_SMALL } from '../../styles/common-styles/borders';

export const headerStyles = ({ expanded }: { expanded: boolean }) => css({
    width: '100%',
    flex: 1,
    flexShrink: 0.25,
    transition: `all 1s`,
});

export const header__title__row = (showAllCategories?: boolean) => css({
    display: 'flex',
    justifyContent: showAllCategories ? 'flex-start' : 'center',
    width: showAllCategories ? 'unset' : '100%',
    alignItems: 'center',
});

export const header__placeholders__container = css({
    background: 'linear-gradient(360deg, #181818 0%, rgba(24, 24, 24, 0) 100%)',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    color: WHITE,
});

export const header__logo = (showAllCategories?: boolean) => css({
    width: '3em',
    height: '3em',
    borderRadius: BORDER_RADIUS_SMALL,
    margin: '0.5em',
});

export const header__team_location = (showAllCategories?: boolean) => css({
    fontSize: FONT_SIZE_M,
    marginBlock: 'unset',
    width: '100%',
    maxWidth: '18em',
    textAlign: showAllCategories ? 'left' : 'center',
});

export const header__background_image__container = (image?: string, borderRadius?: string) => css({
    width: '100%',
    height: '100%',
    backgroundImage: image ? `url(${ image })` : '',
    backgroundColor: BLUE_BASIC,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    borderRadius: `${ borderRadius } ${ borderRadius } 0 0`,
});

export const header__welcome_message__container = css({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
});

export const header__welcome_message = css({
    width: '80%',
    textAlign: 'center',
    fontWeight: '700',
    fontSize: FONT_SIZE_XXXL,
    marginBottom: '0.5em',
    wordBreak: 'break-word',
});

export const header__team_name = (showAllCategories: boolean) => css({
    textAlign: showAllCategories ? 'left' : 'center',
    maxWidth: '18em',
    fontWeight: '700',
    lineHeight: '1.25em',
    fontSize: FONT_SIZE_XXL,
});

export const header__team_info_container = (showAllCategories: boolean) => css({
    display: 'flex',
    flexDirection: showAllCategories ? 'row' : 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingBottom: '0.5em',
});

export const header__team_info__text_container = (showAllCategories: boolean) => css({
    width: showAllCategories ? 'unset' : '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: showAllCategories ? 'flex-start' : 'center',
});
