/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {jsx} from '@emotion/react';
import React from "react";
import {TextField} from "@mui/material";
import {text__input} from "./text-input.styles";

interface TextInputProps {
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;

    placeholder?: string;
    defaultValue?: string;
    focused?: boolean;
    type?: React.InputHTMLAttributes<unknown>['type'];
    required?: boolean;
    disabled?: boolean;
    hasError?: boolean;
    errorMessage?: string;
    autocomplete?: string;
    autocompleteName?: string;
    onBlur?: () => void;
    isFull?: boolean;
    multiline?: boolean;
    maxLines?: number;

    endAdornment?: React.ReactNode;
}

export const TextInput =
    ({
         hasError,
         onBlur,
         onChange,
         autocomplete,
         autocompleteName,
         type,
         required,
         disabled,
         placeholder,
         defaultValue,
         focused,
         isFull,
         multiline,
         maxLines,
         errorMessage,
     }: TextInputProps) => {
        return (
            <TextField
                css={text__input(isFull)}
                variant={"filled"}
                type={type}
                placeholder={placeholder}
                value={defaultValue}
                required={required}
                disabled={disabled}
                name={autocompleteName}
                multiline={multiline}
                maxRows={maxLines}
                error={hasError}
                onChange={onChange}
                onBlur={onBlur}
                autoFocus={focused}
                helperText={hasError ? errorMessage : undefined}
                autoComplete={autocomplete}
                size={"small"}
            />
        );
    };
