/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import {css, jsx} from '@emotion/react';
import React, {Fragment} from "react";
import {GREY_DARK, GREY_LIGHT, WHITE} from "../../styles/common-styles/color-palette";
import {BORDER_RADIUS_MEDIUM, BORDER_RADIUS_SMALL} from "../../styles/common-styles/borders";
import {MainLogo} from "../common/main-logo";
import {FONT_SIZE_M} from "../../styles/common-styles/font-sizes";
import {skeleton__preloader} from "../../styles/common-styles/skeleton";

const watermark__outer_container = css({
    width: '100%',
    height: '4em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: WHITE,
});

const watermark__inner_container = css({
    backgroundColor: GREY_LIGHT,
    borderRadius: `${BORDER_RADIUS_MEDIUM} ${BORDER_RADIUS_MEDIUM} 0 0`,
    margin: '0 0.5em',
    width: '95%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});
const watermark__logo = css({
    color: GREY_DARK,
    paddingRight: '1em',
    height: '1.5em',
    flex: 1,
    borderRight: `${GREY_DARK} 1px solid`
});
const watermark__disclaimer__container = css({width: '50%', marginLeft: '1em', flex: 1.5});
const watermark__disclaimer = css({fontSize: FONT_SIZE_M, color: GREY_DARK, textAlign: 'left'});

interface WatermarkProps {
    appLoading?: boolean;
}

export const Watermark = ({appLoading}: WatermarkProps) => {
    return (
        <div css={watermark__outer_container}>
            <div css={watermark__inner_container}>
                {appLoading && (
                    <div css={[skeleton__preloader, css({
                        height: '100%',
                        width: '100%',
                        borderRadius: BORDER_RADIUS_SMALL,
                    })]}/>
                )}
                {!appLoading && (
                    <Fragment>
                        <div css={watermark__logo}>
                            <a href={'https://magictap.me'}>
                                <MainLogo/>
                            </a>
                        </div>
                        <div css={watermark__disclaimer__container}>
                            <p css={watermark__disclaimer}>
                                Ability to better <br/> understand customers
                            </p>
                        </div>
                    </Fragment>
                )}
            </div>
        </div>
    );
};

export default Watermark;
