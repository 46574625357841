/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { Stack } from "@mui/material";
import React, { useCallback } from "react";
import { category__chips } from "./category.styles";
import { Category as CategoryModel } from "../../../models/backend-api";

import { isShowMoreSpecialCategory } from './utils';
import { WidgetPhrases } from '../../../models/widget-model';

interface CategoryProps {
    phrases: WidgetPhrases;

    category: CategoryModel;
    isSelected: boolean;
    isPositive: boolean;

    onCategorySelect: (category: CategoryModel) => void;
    onShowAllCategories: () => void;
}

export const Category = ({
                             phrases,
                             category,
                             isSelected,
                             isPositive,
                             onCategorySelect,
                             onShowAllCategories,
                         }: CategoryProps) => {
    const onCategorySelectHandler = useCallback(() => {
        if(isShowMoreSpecialCategory(category, phrases)) {
            onShowAllCategories();
            return;
        }
        onCategorySelect(category);
    }, [onCategorySelect, onShowAllCategories, category, phrases]);

    return (
        <Stack
            css={ category__chips({ isSelected, isPositive }) }
            alignItems="center"
            direction="row"
            onClick={ onCategorySelectHandler }
        >
            <p>{ category.icon }</p>
            <p css={ css({ marginLeft: '0.25em' }) }>{ category.label }</p>
        </Stack>
    );
};
